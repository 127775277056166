
const constants = {
  email: "sgcsupport@calpoly.edu",
  discordUrl: "https://discord.gg/BuCEEdRDES",
  eventWebUrl: "https://cci.calpoly.edu/events",
  prequalZip: "https://ccic-web-archive.s3.us-west-2.amazonaws.com/Locked.zip",
  hardwareRequirements:
    "https://ccic-web-archive.s3.us-west-2.amazonaws.com/SGC+2022+Minimum+Hardware+Requirements.pdf",
  rdpInstructions: "https://ccic-web-archive.s3.us-west-2.amazonaws.com/SGC+2022+Minimum+Hardware+Requirements.pdf",
  teamUploadTemplate: "https://ccic-web-archive.s3.us-west-2.amazonaws.com/template_teams.csv",
  userUploadTemplate: "https://ccic-web-archive.s3.us-west-2.amazonaws.com/template_users.csv",
  prequal_questions: "prequal_questions",
  prequal_flag: "prequal_flag",
  prequal_room: "prequal_room",
  prequal_discord: "prequal_discord"
}

export default constants;