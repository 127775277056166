const aws_exports = {
  Auth: {
    region: "us-east-1",
    userPoolId: "us-east-1_uGwVg5ewT",
    userPoolWebClientId: "7r3e4rhshaauv0c0e822dktj86",
    identityPoolId: "us-east-1:08932fd2-e235-43ff-97ff-a962b1bfad27"
  },
  API: { endpoints: [{ name: "code", endpoint: "https://5jusophut1.execute-api.us-east-1.amazonaws.com/PROD/" }] },
  Websocket: "wss://1u9gujxey6.execute-api.us-east-1.amazonaws.com/PROD"
}
export default aws_exports
