import PropTypes from "prop-types"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import { Typography } from "@mui/material"
import logo from "../images/footerLogo.png"
import "./component_css/FooterwSocial.css"
import constants from "../constants.js"

function FooterwSocial(props) {
  return (
    <Container maxWidth={"100%"}>
      <Grid container rowSpacing={4} direction="column">
        <Grid container columnSpacing={4} direction="row" justifyContent="space-evenly">
          <Grid item>
            <Box sx={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
              <Typography className="footer-subtitle" variant="h" marginBottom="-30px">
                Train
              </Typography>
              <Typography className="footer-body" variant="h" fontSize="16px" padding="2px">
                <a href="https://cci.calpoly.edu/about-cci">About CCI</a>
              </Typography>
              <Typography className="footer-body" variant="h" fontSize="16px" padding="2px">
                <a href="https://cci.calpoly.edu/training/training-catalog">Find Courses</a>
              </Typography>
              <Typography className="footer-body" variant="h" fontSize="16px" padding="2px">
                <a href="https://cci.calpoly.edu/">Customize Your Training </a>
              </Typography>
              <Typography className="footer-body" variant="h" fontSize="16px" padding="2px">
                <a href="https://cci.calpoly.edu/training/explore-cybersecurity-careers">
                  Explore Cybersecurity Careers
                </a>
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
              <Typography className="footer-subtitle" variant="h" marginBottom="-30px">
                Accelerate
              </Typography>
              <Typography className="footer-body" variant="h" fontSize="16px" padding="2px">
                <a href="https://cci.calpoly.edu/research/cyber-initiative">Cyber Initiative</a>
              </Typography>
              <Typography className="footer-body" variant="h" fontSize="16px" padding="2px">
                <a href="https://cci.calpoly.edu/research/space-commercialization">Space Commercialization</a>
              </Typography>
              <Typography className="footer-body" variant="h" fontSize="16px" padding="2px">
                <a href="https://cci.calpoly.edu/research/supply-chain-security">Supply Chain Security</a>
              </Typography>
              <Typography className="footer-body" variant="h" fontSize="16px" padding="2px">
                <a href="https://dxhub.calpoly.edu/">DxHub</a>
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box className="footer-box" sx={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
              <Typography className="footer-subtitle" variant="h" marginBottom="-30px">
                Empower
              </Typography>
              <Typography className="footer-body" variant="h" fontSize="16px" padding="2px">
                <a href="https://securelb.imodules.com/s/699/hybrid-form/index.aspx?sid=699&gid=1&pgid=961&cid=2272&bledit=1&dids=418">
                  Give to CCI
                </a>
              </Typography>
              <Typography className="footer-body" variant="h" fontSize="16px" padding="2px">
                <a href="https://cci.calpoly.edu/empower/cyber-to-schools">Cyber to Schools Initiative</a>
              </Typography>
              <Typography className="footer-body" variant="h" fontSize="16px" padding="2px">
                <a href="https://cci.calpoly.edu/empower/jobs-internships">Jobs & Internships</a>
              </Typography>
              <Typography className="footer-body" variant="h" fontSize="16px" padding="2px">
                <a href="https://cci.calpoly.edu/empower/student-stories">Student Stories</a>
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
              <Typography className="footer-body" pb={"23px"} variant="h">
                ______________________
              </Typography>
              <Typography variant="h6" className="calpoly_one" pb={"4%"} style={{ color: "white" }}>
                Cal Poly California Cybersecurity Institute
              </Typography>
              <Typography className="calpoly_one" variant="h7" pb={"1%"} style={{ color: "white" }}>
                Contact CCI
              </Typography>
              <Typography className="calpoly_one" variant="subtitle1" style={{ color: "white" }}>
                <a href="mailto:sgcsupport@calpoly.edu">{constants.email}</a>
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid
          item
          container
          columnSpacing={3}
          direction="row"
          pl={"10%"}
          paddingTop="20px !important;"
          justifyContent="left"
          alignItems="center">
          <Grid item>
            <Box component="img" sx={{ height: 40 }} alt="Logo" src={logo} />
          </Grid>
          <Grid item>
            <Typography className="footer-body-large" variant="h5" justifyContent="left">
              <div className="calpoly">CAL POLY | 1 Grand Avenue, San Luis Obispo, CA 93407 | 805-756-1111 </div>
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          columnSpacing={5}
          direction="row"
          pl={"10%"}
          paddingTop="1px !important;"
          style={{ color: "white" }}
          justifyContent="left"
          alignItems="center">
          <Grid item>
            <Typography className="specialfooter" style={{ fontWeight: "bold" }} variant="subtitle2">
              <a style={{ fontWeight: "bold" }} href="https://www.calpoly.edu/">
                UNIVERSITY HOME
              </a>
            </Typography>
          </Grid>
          <Grid item>
            <Typography className="specialfooter" style={{ fontWeight: "bold" }} variant="subtitle2">
              <a style={{ fontWeight: "bold" }} href="https://my.calpoly.edu/">
                MY CAL POLY PORTAL
              </a>
            </Typography>
          </Grid>
          <Grid item>
            <Typography className="specialfooter" style={{ fontWeight: "bold" }} variant="subtitle2">
              <a style={{ fontWeight: "bold" }} href="https://maps.calpoly.edu/">
                CAMPUS MAP
              </a>
            </Typography>
          </Grid>
          <Grid item>
            <Typography className="specialfooter" style={{ fontWeight: "bold" }} variant="subtitle2">
              <a style={{ fontWeight: "bold" }} href="https://www.calpoly.edu/diversity-equity-and-inclusion">
                DIVERSITY, EQUITY, AND INCLUSION
              </a>
            </Typography>
          </Grid>
          <Grid item>
            <Typography className="specialfooter" style={{ fontWeight: "bold" }} variant="subtitle2">
              <a style={{ fontWeight: "bold" }} href="https://chw.calpoly.edu/">
                HEALTH AND WELLBEING
              </a>
            </Typography>
          </Grid>
          <Grid item>
            <Typography className="specialfooter" style={{ fontWeight: "bold" }} variant="subtitle2">
              <a style={{ fontWeight: "bold" }} href="https://afd.calpoly.edu/public-safety/">
                CAMPUS SAFETY
              </a>
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          columnSpacing={5}
          direction="row"
          style={{ color: "white" }}
          pl={"10%"}
          paddingTop="1px !important;"
          justifyContent="left"
          alignItems="center">
          <Grid item>
            <Typography className="specialfooter-sub" variant="subtitle3" fontSize="14px !important;">
              <a href="https://www.calpoly.edu/privacy">Privacy Notice</a>
            </Typography>
          </Grid>
          <Grid item>
            <Typography className="specialfooter-sub" variant="subtitle3" fontSize="14px !important;">
              <a href="https://universitymarketing.calpoly.edu/website-feedback/">Website Feedback</a>
            </Typography>
          </Grid>
          <Grid item>
            <Typography className="specialfooter-sub" variant="subtitle3" fontSize="14px !important;">
              <a href="https://accessibility.calpoly.edu/website-accessibility-statement">
                Web Accessibility Statement
              </a>
            </Typography>
          </Grid>
          <Grid item>
            <Typography className="specialfooter-sub" variant="subtitle3" fontSize="14px !important;">
              <a href="https://crco.calpoly.edu/content/title-ix">Title IX</a>
            </Typography>
          </Grid>
          <Grid item>
            <Typography className="specialfooter-sub" variant="subtitle3" fontSize="14px !important;">
              <a href="https://afd.calpoly.edu/clery/reports/annual-security-report.pdf">
                Campus Annual Security Report (PDF)
              </a>
            </Typography>
          </Grid>
          <Grid item>
            <Typography className="specialfooter-sub" variant="subtitle3" fontSize="14px !important;">
              <a href="https://afd.calpoly.edu/clery/reports/campus-safety-plan.pdf">Campus Safety Plan (PDF)</a>
            </Typography>
          </Grid>
          <Grid item>
            <Typography className="specialfooter-sub" variant="subtitle3" fontSize="14px !important;">
              <a href="https://get.adobe.com/reader/">PDF Reader</a>
            </Typography>
          </Grid>
          <Grid item>
            <Typography className="specialfooter-sub" variant="subtitle3" fontSize="14px !important;">
              <a href="https://coronavirus.calpoly.edu/">COVID-19 Information</a>
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          columnSpacing={5}
          direction="row"
          pl={"12%"}
          paddingTop="5px !important;"
          justifyContent="left"
          alignItems="center">
          <Typography className="footer-body-small" variant="subtitle1">
            &copy; {new Date().getFullYear()} California Polytechnic State University
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}

FooterwSocial.propTypes = {
  lightFooter: PropTypes.bool.isRequired
}

export default FooterwSocial